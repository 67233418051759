<template>
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <TitlePlus
              :title="$t('components.taskManagement.headline')"
              :hide-plus="$acl.canNotCreate('tasks')"
              @plus="onEdgeStack({ esId: addEdit.esId })"
            />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <div class="summary-card-container">
            <SummaryCard
              :title="$t('components.taskManagement.summary.total')"
              :value="indexMetaData.summary.total"
              variant="gray"
            />
            <SummaryCard
              :title="$t('components.taskManagement.summary.ongoing')"
              :value="indexMetaData.summary.ongoing"
              variant="gray"
            />
            <SummaryCard
              :title="$t('components.taskManagement.summary.rebalancing')"
              :value="indexMetaData.summary.rebalancing"
              variant="gray"
            />
            <SummaryCard
              :title="$t('components.taskManagement.summary.charging')"
              :value="indexMetaData.summary.charging"
              variant="gray"
            />
            <SummaryCard
              :title="$t('components.taskManagement.summary.completed')"
              :value="indexMetaData.summary.done"
              variant="gray"
            />
          </div>
        </div>
      </div>

      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="getTableHeadersTitle"
        :endpoint="endpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex">
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{ name: 'ViewTaskDetails', params: { id: item.id } }"
                />
                <FSTableRowItem :text="item.title">
                  <template #default="{ text }">
                    <span
                      v-text="text"
                      class="text-blue-600 cursor-pointer"
                      @click="
                        onEdgeStack({ esId: details.esId, primaryKey: item.id })
                      "
                    />
                  </template>
                </FSTableRowItem>

                <FSTableRowItem>
                  <span class="capitalize">
                    {{ formatTaskType(item.task_type) }}
                  </span>
                </FSTableRowItem>

                <FSTableRowItem
                  v-if="!hideColumns.vehicle"
                  :to="{
                    name: 'ViewVehicleProfile',
                    params: { id: item.vehicle.id },
                  }"
                  :text="item.vehicle.qr_code"
                  target="_blank"
                />

                <FSTableRowItem :text="item.created_at" date />

                <FSTableRowItem>
                  {{ getDate(item.due_by) }}
                </FSTableRowItem>

                <FSTableRowItem>
                  <div class="flex flex-col">
                    <RouterLink
                      :to="{
                        name: 'ViewOrganizationUserProfile',
                        params: { id: item.user.id },
                      }"
                      target="_blank"
                      class="text-blue-600"
                    >
                      {{ item.user.full_name }}
                    </RouterLink>
                    <span class="text-sm text-gray-600">
                      ({{ item.user.organization_role }})
                    </span>
                  </div>
                </FSTableRowItem>

                <FSTableRowItem :text="item.task_status">
                  <template #default="{ text }">
                    <TaskListStatusDropdown
                      :primaryKey="item.id"
                      :taskStatus="text"
                    />
                  </template>
                </FSTableRowItem>

                <FSTableRowItem>
                  <TaskMoreActionsDropdown :data="item" :key="item.id" />
                  <!-- <i class="text-sm text-gray-600 fas fa-ellipsis-v" /> -->

                  <!-- <AppButton
                variant="secondary"
                text="View Task"
                @click="onEdgeStack({ esId: details.esId })"
              /> -->
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow
                :key="`fs-table-row-${itemIndex}`"
                text-fallback-always
              >
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{ name: 'ViewTaskDetails', params: { id: item.id } }"
                />

                <FSTableRowItem>
                  <span class="capitalize">
                    {{ formatTaskType(item.task_type) }}
                  </span>
                </FSTableRowItem>

                <FSTableRowItem :text="item.task_status">
                  <template #default="{ text }">
                    <TaskListStatusDropdown
                      :primaryKey="item.id"
                      :taskStatus="text"
                    />
                  </template>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{ $t('components.taskManagement.table.columns.title') }}
                    </div>
                    <div class="col-span-5 right-text">
                      <span
                        v-text="item.title"
                        class="text-blue-600 cursor-pointer"
                        @click="
                          onEdgeStack({
                            esId: details.esId,
                            primaryKey: item.id,
                          })
                        "
                      />
                    </div>
                  </div>
                  <div
                    v-if="!hideColumns.vehicle"
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.taskManagement.table.columns.vehicleQR')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <RouterLink
                        :to="{
                          name: 'ViewVehicleProfile',
                          params: { id: item.vehicle.id },
                        }"
                        target="_blank"
                        class="text-blue-600"
                      >
                        {{ item.vehicle.qr_code }}
                      </RouterLink>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.taskManagement.table.columns.createdAt')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getUTCAwareTime(item.created_at) || `--` }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.taskManagement.table.columns.dueDate')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getUTCAwareTime(item.updated_at) || `--` }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.taskManagement.table.columns.assignedTo')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div class="flex flex-col">
                        <RouterLink
                          :to="{
                            name: 'ViewOrganizationUserProfile',
                            params: { id: item.user.id },
                          }"
                          target="_blank"
                          class="text-blue-600"
                        >
                          {{ item.user.full_name }}
                        </RouterLink>
                        <span class="text-sm text-gray-600">
                          ({{ item.user.organization_role }})
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.taskManagement.table.columns.actions')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <TaskMoreActionsDropdown :data="item" :key="item.id" />
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>

    <TaskDetails
      :esId="details.esId"
      :busy="details.busy"
      :rawData="details.rawData"
      :primaryKey="details.primaryKey"
      @edgestack="
        onEdgeStack({ esId: addEdit.esId, primaryKey: $event.taskId })
      "
    />

    <TaskAddEdit
      v-if="$acl.canCreate('tasks')"
      :esId="addEdit.esId"
      :busy="addEdit.busy"
      :formData="addEdit.formData"
      :vehicleOptions="addEdit.vehicleOptions"
      :primaryKey="addEdit.primaryKey"
      @save="$store.dispatch('fsTable/fetchData')"
      @edgestack="onEdgeStack({ esId: details.esId })"
    />
  </section>
</template>

<script>
import { useEndpoints } from '@/composables'
import { useEdgeStack } from '@/plugins/edgeStack'
import { getUTCAwareTime } from '@/utils'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import { generateFilterOptions, formatSummaries } from '.'

import TaskDetails from './Details.vue'
import TaskAddEdit from './AddEdit.vue'
import TaskListStatusDropdown from './ListStatusDropdown.vue'
import TaskMoreActionsDropdown from './MoreActionsDropdown.vue'
import dayjs from 'dayjs'
// import XStatus from '@/components/badge/XStatus'

export default {
  name: 'TaskList',

  props: {
    pageTitle: {
      type: String,
      default: 'Tasks',
    },
    fstId: {
      type: String,
      default: 'taskList',
    },
    endpoint: {
      type: String,
      default: useEndpoints.task.index(),
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    hideColumns: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    // XStatus,

    TaskDetails,
    TaskAddEdit,
    TaskListStatusDropdown,
    TaskMoreActionsDropdown,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      indexMetaData: {
        summary: {
          charging: 0,
          done: 0,
          ongoing: 0,
          rebalancing: 0,
          total: 0,
        },
      },

      filterOptions: [],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t('components.taskManagement.table.columns.taskId')}`,
          width: '20%',
          sort: 'id',
        },
        {
          text: `${this.$t('components.taskManagement.table.columns.type')}`,
          width: '40%',
          sort: 'task_type',
        },
        {
          text: `${this.$t(
            'components.taskManagement.table.columns.taskStatus'
          )}`,
          width: '30%',
          sort: 'payment_for',
        },
      ],
      opened: [],

      addEdit: {
        esId: 'task-addEdit',

        // XStepper component
        // stepperMode: 'strict',
        // stepperStep: 1,

        // Data
        busy: false,
        rawData: null,
        primaryKey: null,
      },

      details: {
        esId: 'task-details',

        busy: false,
        formData: null,
        vehicleOptions: null,
        primaryKey: null,
      },
    }
  },

  async created() {
    this.filterOptions = await generateFilterOptions()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    getTableHeadersTitle() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.getHeaders(this.hideColumns)
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },

  methods: {
    onApplyFilterDateRange,
    getUTCAwareTime,
    getTableHeaders(hideColumns = {}) {
      const headers = []
      if (!hideColumns.id) {
        headers.push({
          text: `${this.$t('components.taskManagement.table.columns.taskId')}`,
          width: '10%',
          sort: 'id',
        })
      }
      if (!hideColumns.title) {
        headers.push({
          text: `${this.$t('components.taskManagement.table.columns.title')}`,
          width: '20%',
          sort: 'id',
        })
      }
      if (!hideColumns.type) {
        headers.push({
          text: `${this.$t('components.taskManagement.table.columns.type')}`,
          width: '15%',
          sort: 'task_type',
        })
      }
      if (!hideColumns.vehicle) {
        headers.push({
          text: `${this.$t(
            'components.taskManagement.table.columns.vehicleQR'
          )}`,
          width: '10%',
          sort: 'user',
        })
      }
      if (!hideColumns.created_at) {
        headers.push({
          text: `${this.$t(
            'components.taskManagement.table.columns.createdAt'
          )}`,
          width: '15%',
          sort: 'date',
        })
      }
      if (!hideColumns.due_date) {
        headers.push({
          text: `${this.$t('components.taskManagement.table.columns.dueDate')}`,
          width: '15%',
          sort: 'payment',
        })
      }
      if (!hideColumns.assigned_to) {
        headers.push({
          text: `${this.$t(
            'components.taskManagement.table.columns.assignedTo'
          )}`,
          width: '10%',
          sort: null,
        })
      }
      if (!hideColumns.status) {
        headers.push({
          text: `${this.$t(
            'components.taskManagement.table.columns.taskStatus'
          )}`,
          width: '10%',
          sort: 'payment_for',
        })
      }
      if (!hideColumns.vehicle) {
        headers.push({
          text: `${this.$t('components.taskManagement.table.columns.actions')}`,
          width: '5%',
          sort: 'amount',
        })
      }
      return headers
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getHeaders(columns) {
      return this.getTableHeaders(columns)
    },
    async onEdgeStack({
      esId = this.details.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
    } = {}) {
      const edgeStack = useEdgeStack()

      // add mode needs no data, clear them out first
      if (!primaryKey && esId === this.addEdit.esId) {
        // add mode
        this.addEdit.busy = false
        this.addEdit.rawData = null
        this.addEdit.primaryKey = null
      }

      // open the edgestack first, load data asynchronally if primary key is provided
      if (esAction === 'toggle') {
        edgeStack.toggle(esId)
      } else if (esAction === 'open') {
        edgeStack.open(esId)
      } else if (esAction === 'close') {
        edgeStack.close(esId)
      } else {
        throw new Error(`Unknown esAction: ${esAction}`)
      }

      // immidiately make edgestack busy & fetch & provide data if primary key is provided
      if (primaryKey && esId === this.details.esId) {
        // view mode
        this.details.busy = true
        this.details.primaryKey = primaryKey
        await this.fetchTaskDetails({ primaryKey })
          .then((res) => {
            this.details.rawData = res.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.details.busy = false))
      } else if (primaryKey && esId === this.addEdit.esId) {
        // edit mode
        this.addEdit.busy = true
        this.addEdit.primaryKey = primaryKey
        await this.fetchTaskDetails({ primaryKey })
          .then((res) => {
            const data = res.data
            const vehicle = data.vehicle

            data.user = data.user.id
            data.due_by = this.$dayjs(data.due_by).format('YYYY-MM-DD')
            data.vehicle = vehicle.id

            this.addEdit.formData = data
            this.addEdit.vehicleOptions = [
              {
                text: vehicle.qr_code,
                value: vehicle.id,
              },
            ]
            console.log('pk', this.addEdit.vehicleOptions)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.addEdit.busy = false))
      }
    },

    async fetchTaskDetails({ primaryKey } = {}) {
      if (!primaryKey) {
        throw new Error('No primaryKey provided [fetchTask]')
      }
      const url = useEndpoints.task.details(primaryKey)
      return await this.$http.get(url)
    },

    formatTaskType(str = '') {
      // we'll use css for converting into title case
      return str.replaceAll(/_/g, ' ').toLowerCase()
    },
    getDate(time) {
      if (time) {
        const d = dayjs(time)
        return d.format('DD MMM, YY')
      } else {
        return '--'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.refund-action {
  color: #ffffff;
  width: 87px;
  height: 25px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  &.refund {
    background-color: #ff5500;
  }

  &.refunded {
    color: #5f72bf;
    background-color: #d7ebff;
  }

  &.disabled {
    background-color: #bebebe;
    color: #ffffff;
  }
}
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
